import React from "react";
import Footer from "../Footer";

import Header from "../Header";
import * as styles from "./styles.module.scss";

const MainLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <main className={styles.wrapper}>{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
