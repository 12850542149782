import React from "react";

import LogoIcon from "../../assets/icons/logo.svg";
import * as styles from "./styles.module.scss";

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <LogoIcon height={30} />
      </div>
    </header>
  );
};

export default Header;
