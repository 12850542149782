import React from "react";
import * as styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.belowWrapper}>
          <div className={styles.text}>
            <span className="pr-4 block md:inline">
              © 2022 VGH Versicherungen
            </span>{" "}
            <a
              href="https://www.vgh.de/content/impressum/"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>{" "}
            |{" "}
            <a
              href="https://www.vgh.de/content/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>{" "}
            |{" "}
            <a
              href="https://www.vgh.de/kontakt"
              target="_blank"
              rel="noreferrer"
            >
              Kontakt
            </a>{" "}
            | <a title="Cookie-Einstellungen">Cookie-Einstellungen</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
